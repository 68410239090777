.proj_containar_section {
  width: 90%;
  padding: 100px 30px;
}

.proj_containar_section,
.proj_left,
.proj_right {
  display: flex;
  align-content: center;
  justify-content: center;
}
.proj_left,
.proj_right {
  flex-wrap: wrap;
  width: 50%;
}
.proj_left {
  text-align: left;
  gap: 12px;
}
.proj_left h3 {
  font-size: 36px;
  flex-wrap: wrap;
  display: flex;
}
.proj_left h1 {
  font-size: 20px;
  text-align: left;
  width: 90%;
  margin-bottom: 10px;
}
.proj_left p {
  line-height: 30px;
  font-size: 18px;
  width: 90%;
}
.proj_left h3,
.proj_left p,
.bot_btn {
  width: 90%;
}
.proj_right img {
  height: 350px;
}

@media screen and (max-width: 920px) {
  .proj_containar_section {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .proj_left,
  .proj_right {
    width: 90%;
  }
  .bot_btn {
    margin: 0;
  }
  .proj_left h1 {
    font-size: 20px;
    text-align: left;
    width: 100%;
    margin-bottom: 50px;
  }
  .proj_left h3,
  .proj_left p,
  .bot_btn {
    width: 100%;
  }
  .proj_left p {
    text-align: left;
  }
}
@media screen and (max-width: 720px) {
  .proj_containar_section {
    flex-direction: column;
  }
  .proj_right img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 420px) {
  .proj_left h3 {
    font-size: 30px;
    text-align: left;
    padding-top: 18px;
  }
  .proj_containar_section {
    padding: 20px 18px;
  }
}

.animate-character
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip1 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip1 {
  to {
    background-position: 200% center;
  }
}
