.btn {
  border-radius: 50px;
  padding: 14px 0;
  width: 160px;
  cursor: pointer;
  transition: 0.2s;
  outline: none;
  border: none;
}

.btn:active {
  transform: translateY(2px);
}

.primary-btn {
  border: 2px solid linen;
  font-size: 12px;
  background-color: var(--dark-fans);
}
.profile-details-name p {
  font-size: 54px;
  line-height: 54px;
  color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  color: #7000f0;;
}
.primary-btn:hover {
  color: aliceblue;
  border: 2px solid #7000f0;
  background-color: #1f2235;
}

.highlighted-btn {
  color: var(--white);
  font-size: 12px;
  background-color: #cf7a0a;
}

@media only screen and (max-width: 568px) {
  .highlighted-btn {
    z-index: -900;
  }
}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}
.profile-container,
.profile-parent {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: -40.5px;
}
.profile-container {
  justify-content: flex-start;
  background-color:#000000;
  flex-direction: column;
  position: sticky;
}
.profile-parent {
  width: 90%;
  text-align: left;
  justify-content: center;
}
.profile-details-name {
  font-size: 30px;
  line-height: 40px;
}
.profile-details-role {
  display: flex;
  flex-direction: column;
  margin: 15px 0 18px 0;
}
.profile-details-role h1 {
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 6px 0;
  color: #4f4f4f;
  /* text-transform: uppercase; */
  text-shadow: 0 0 9px #1818188f;
}
.colz {
  padding: 12px 0px;
}
.primary-text {
  color: (--white);
  font-size: 24px;
  width: 90%;
}

.highlighted-text {
  text-shadow: 0 0 9px #1d1122;
  color: #ffffff;
  margin-right: 9px;
  margin-left: 12px;
  font-weight: 600;

}
.profile-role-tagline {
  font-size: 16px;
  line-height: 24px;
  margin: 5px 0 0 0;
  color: rgb(127, 141, 170);
  text-shadow: 0 0 9px #001339bf;
}
.profile-options .highlighted-btn {
  margin: 0 0 0 28px;
}
.profile-options .highlighted-btn:hover {
  margin: 0 0 0 28px;
  background-color: cornsilk;
  color: #111;
}
.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px 2px #7000f0;
  height: 380px;
  width: 380px;
  border-radius: 100%;
  animation: example 3s infinite;

}

.profile-picture-background {
  height: 120%;
  width: 120%;
  background-image: url(../Image/Profile_imgs/dp.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  clip-path: circle(40%);
}
.profile-picture-background:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}
.colz .colz-icon i {
  color: #fff;
  padding: 8px;
  padding-left: 0px;
  font-size: 18px;
}

.colz .colz-icon i:hover {
  transform: scale(1.5);
}

@media screen and (min-width: 1024px) {
  .profile-details {
    width: 50%;
  }
  .profile-parent {
    height: 100vh;
  }
  .profile-picture {
    margin: 0 0px 0 80px;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font-size: 12px;
  }
  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
    z-index: 700;
    height: 47px;
    color: #fff;
  }
}

@media screen and (max-width: 1024px) {
  .profile-details-name p {
    font-size: 45px;
    line-height: 54px;
  }
  .profile-parent {
    height: 100vh;
  }
}
@media screen and(max-width:910px) {
  .profile-picture {
    height: 320px;
    width: 320px;
    margin: 0 0 0 4px;
  }
}
@media screen and (max-width: 810px) {
  .profile-container {
    width: 100%;
    background-color: #000000;
    padding: 18px 0 21px 0;
    background-size: cover;
    clip-path: circle(100% 0, 100% 96%, 54% 100%, 0 94%, 0 0);
  }
  .profile-picture {
    margin-bottom: 30px;
  }
  .profile-details-role h1 {
    height: 100px;
    text-align: center;
    display: none !important;
  }
  .profile-details-name p {
    font-size: 30px;
    line-height: 36px;
    text-decoration-color: #7000f0;;
  }
  .profile-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
    width: 90%;
    height: 100% !important;
    justify-content: center;
    text-align: center;
  }
  .profile-options {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  .profile-deails {
    display: flex;
    margin-top: 15px;
    margin: 25px 0 0;
    justify-content: center;
    text-align: center;
  }
  .primary-text {
    line-height: 24px;
  }

  .colz .colz-icon i {
    color: #ffffff;
    text-shadow: 0px 0px 1px #ffffff;
  }
  .profile-options .highlighted-btn {
    margin: 0 !important;
  }
  .profile-options button {
    font-size: 14px !important;
  }
  .profile-options,
  .profile-details-role {
    text-align: center;
  }
  .profile-options {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 480px) {
  .primary-text {
    width: 100%;
  }
  .profile-parent {
    margin: 0 !important;
  }
  .profile-deails {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-options {
    z-index: 10;
    position: relative;
  }
}
@media screen and (max-width: 420px) {
  .profile-picture {
    height: 342px;
    width: 342px;
  }
  .profile-picture-background {
    height: 85%;
    width: 85%;
  }
  .profile-details-name {
    margin: 6px 0 0 0;
  }
  .profile-details-role h1 {
    margin: 0 0 6px 0;
    text-align: center;
    height: 90px;
  }
}
@media screen and (max-width: 375px) {
  .profile-picture {
    height: 275px;
    width: 278px;
  }
  .profile-deails {
    width: 90%;
  }
}

@media screen and (max-width: 330px) {
  .profile-options {
    flex-direction: column;
    gap: 20px;
  }

  
}

@keyframes example {
  33% {box-shadow: 2px 4px 3px 2px #ffffff;}
  66% {box-shadow: 4px 2px 3px 1px #0aa1cf;}
  100% {box-shadow: 2px 2px 3px 5px #7000f0;}
}

