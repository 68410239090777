.about_me {
  width: 100%;
}
.about_me,
.container_01,
.my_img,
.my_info,
.personal_info {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.container_01 {
  width: 100%;
  padding: 60px 0;
}
.my_img {
  width: 45%;
  margin: 67px 0 35px 0;
}
#img {
  display: flex;
}
.my_info h2 {
  margin: 0px !important;
}
h2 {
  margin: 10px;
  font-size: 42px;
}
h2 span {
  color: #f18e0b;
}
.pop-outin {
  animation: 5s anim-popoutin ease infinite;
}
.my_info {
  width: 80%;
  color: #000;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 35px;
  font-family: system-ui;
}
.my_info p {
  width: 90%;
  margin: 3px;
  font-size: 15px;
  color: #000;
  padding: 2px 15px 20px 15px;
}
h4 {
  font-size: 17px;
  font-weight: 500;
}
h4 span {
  font-size: 18px;
  font-weight: 600;
  color: #7000f0;
}
.personal_info {
  width: 55%;
  justify-content: space-around;
  flex-direction: row;
}

@function textShadow($precision, $size, $color){
  $value: null;
  $offset: 0;
  $length: $size * (1 / $precision) - 1;

  @for $i from 0 through $length {
    $offset: $offset + $precision;
    $shadow: $offset + px $offset + px $color;
    $value: append($value, $shadow, comma);
  }

  @return $value;
}

.playful span {
  position: relative;
  color: #691ff3;
  text-shadow: textShadow(0.25, 6, #bb85f8);
  animation: scatter 1s infinite;
}

.playful span:nth-child(2n) {
  color: #6be2a0;
  text-shadow: textShadow(0.25, 6, #691ff3);
  animation-delay: 0.3s;
}

.playful span:nth-child(3n) {
  color: #691ff3;
  text-shadow: textShadow(0.25, 6, #6EC0A9);
  animation-delay: 0.15s;
}

.playful span:nth-child(5n) {
  color: #691ff3;
  text-shadow: textShadow(0.25, 6, #e0d48e);
  animation-delay: 0.4s;
}

.playful span:nth-child(7n), .playful span:nth-child(11n) {
  color: #691ff3;
  text-shadow: textShadow(0.25, 6, #FF5555);
  animation-delay: 0.25s;
}

@keyframes scatter {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

h1 {
  font-size: 34px;
  font-family: "Archivo Black", "Archivo", sans-serif;

}



@media screen and (min-width: 1024px) {
  .my_info {
    gap: 27px;
  }
  .my_info p {
    font-size: 21px;
  }
  .my_img {
    width: 45%;
  }
  .my_img img {
    width: 450px;
  }

  h1 {
    font-size: 34px;
    font-family: "Archivo Black", "Archivo", sans-serif;
    margin-top: 20px;
  
  }
}
@media screen and (max-width: 1024px) {
  .my_img img {
    width: 523px;
  }
  .my_info {
    gap: 27px;
  }
h1{
  margin-top: 20px;
}
}
@media screen and (max-width: 900px) {
  .my_img img {
    width: 550px;
  }
  h1{
    margin-top: 20px;
  }
}
@media screen and (max-width: 720px) {
  .container_01 {
    flex-direction: column;
  }
  .personal_info {
    flex-direction: column;
    margin: 1px 0px 15px 0px;
  }
  h1{
    margin-top: 20px;
  }
}
@media screen and (max-width: 580px) {
  .my_img {
    width: 90%;
    margin: 35px 0 5px 0 !important;
  }
  h1{
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .container_01 {
    width: 100%;
    flex-direction: column;
    position: relative;
    color: #fff;
    padding: 10px 0;
  }
  .my_img img {
    width: 100%;
  }
  .my_info {
    width: 90%;
  }
  .my_info 
   {
    margin: 10px;
  }
  .my_info p {
    width: 100%;
  }
  .personal_info {
    width: 70%;
  }
  h1{
    margin-top: 20px;
  }
}
