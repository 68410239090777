.my_skills {
  display: flex;
  justify-content: center;
  margin: 80px 0 0 0;
  padding: 13px 0 0 0;
}

.my_skills,
.section_container_skills_gif,
.container_s {
  background-color: white;
  
}
.my_skills span {
  color: #ff5823;
}
.my_skills h1 {
  font-size: 30px;
  font-family:Verdana, Geneva, Tahoma, sans-serif

}
h2 {
  width: 90% !important;
  margin: 0px !important;
  text-align: center !important;
}
.my_skills h2 {
  color: #000;
}
#my_skill {
  background: #b0bec5;
}
.section_container_skills_gif {
  width: 100%;
  flex-direction: column;
  padding: 19px 0;
}
.skills_gif_container {
  width: 90%;
  gap: 12px;
}
.section_container_skills_gif,
.skills_gif_container div,
.skills_gif_container img,
.skill_box,
.content_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.skills_gif_container div {
  border-radius: 40px;
  height: 82px;
  flex-wrap: wrap;
  width: 64%;
  background-color: rgba(107, 164, 221, 0.07);
  font-weight: 500;
  color: #000;
}
.skills_gif_container img {
  width: 100%;
  margin-bottom: 20px;
}
.container_s {
  z-index: 50;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px 0;
}
.skill_box {
  border: none;
  gap: 0.5rem;
  width: 200px;
  flex-direction: column;
  margin: 9px;
  transition: ease-in-out 0.2s;
}
.skill_box:hover {
  border-radius: 12px;
}
.skill_box:active {
  transform: scale(0.9);
}

.svg_blobs {
  position: relative;
  z-index: 1;
  top: 269px;
  width: 100%;
  height: 400px;
}
.content_img_container img {
  width: 3.5rem;
  height: 3.5rem;
}
.content_img_container {
  flex-direction: column;
  gap: 10px;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: rgba(107, 164, 221, 0.07);
}
.content_img_container h3 {
  font-size: 24px;
}
@media screen and (min-width: 1024px) {
  .skills_gif_container {
    display: flex;
    justify-content: center;
    margin: 10px 0 30px 0;
  }
  .skills_gif_container div {
    padding: 9px;
    font-size: 18px;
    text-align: center;
    height: 109px;
  }
  .skills_gif_container img {
    width: 900px;
    height: 500px !important;
  }
}

@media screen and (max-width: 1023px) {
  .skills_gif_container {
    display: flex;
    justify-content: center;
    margin: 10px 0 30px 0;
    width: 100%;
  }
  .skills_gif_container div {
    padding: 32px;
    font-size: 18px;
    text-align: center;
    height: 109px;
  }
}

@media screen and (max-width: 720px) {
  .my_skills {
    margin: 0;
    padding: 15px 0;
  }
  .skills_gif_container {
    flex-direction: column;
    align-items: center;
    gap: 33px;
  }
  .skills_gif_container div {
    width: 90%;
    padding: 74px;
    font-size: 16px;
  }
}

@media screen and (max-width: 580px) {
  .skills_gif_container div {
    height: 106px;
    padding: 26px;
  }
  .skill_box {
    width: 150px;
    height: 150px;
  }
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #060706 0%,
    #0e0616 29%,
    #7000f0 67%,
    #7000f0 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}