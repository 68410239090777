.contact_page_section {
  padding: 60px 0;
  background: rgb(255, 255, 255);
}
.contact_section {
  width: 90%;
  flex-direction: column;
  background: #fff;
}
.contact_page_section,
.contact_section,
.contact_container,
.form_container_right,
.form_container_left,
.user_form,
.user_send_msg_button,
.submit_msg,
.user_message_form h3,
.user_message_form p,
.contact_data_class {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.contact_container,
.contact_page_section,
.user_form,
.user_message_form,
.your_info_data {
  width: 100%;
}

.form_container_right {
  width: 90%;
}

.user_message_form {
  padding: 32px 0;
}
.username_val,
.user_msg,
.name_val {
  display: flex;
  flex-direction: column;
  margin: 9px;
}
input {
  height: 45px;
  line-height: 55px;
}

.submit_msg {
  height: 45px;
  width: 95%;
  gap: 9px;
  background: #cf7a0a;
  border: 1px solid #cf7a0a;
  color: #fff;
  margin: 15px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  transition: ease-in-out 0.5s;
  border-radius: 3px;
}
.submit_msg:active {
  transform: scale(0.9);
}
input,
textarea {

  outline: none;
  padding-left: 20px;
}
textarea {
  padding-top: 12px;
}
label {
  font-size: 15px;
  font-weight: 600;
}
label,
input {
  margin-bottom: 9px;
}
.user_message_form span {
  color: #cf7a0a;
  margin-left: 5px;
}
.user_message_form h3 {
  font-size: 39px;
}
.user_message_form p {
  font-size: 18px;
}
.contact_data_class {
  gap: 10px;
  margin: 15px 0 21px 0;
  font-size: 18px;
  font-family: inherit;
  padding: 10px 21px;
}
.form_container_left {
  width: 50%;
}
@media screen and (min-width: 1024px) {
  .contact_page_section {
    padding: 70px 0;
  }
  .contact_container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 10px;
    flex-direction: row-reverse;
  }
  .contact_section {
    width: 1042px;
  }
  .user_form {
    width: 80%;
  }
  .form_container_right {
    background-size: cover;
    width: 590px;
    height: 621px;
  }
  .contact_data_class {
    margin: 30px 0 30px 0;
  }
  .form_container_left .form_container_right {
    width: 50%;
  }

  .your_info_data {
    margin: 0 0 0 10px;
  }

  .contact_data_class {
    gap: 14px;
  }
  .user_message_form h3,
  .user_message_form p {
    margin-left: 8px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .form_container_right {
    width: 100%;
  }
  .contact_container {
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }
  .contact_section {
    height: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 720px) {
  .form_container_left {
    width: 90%;
    margin: 0px;
  }
}

@media screen and (max-width: 580px) {
  .user_message_form h3,
  .user_message_form p {
    margin-left: 8px;
    justify-content: flex-start;
  }
}
